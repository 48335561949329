<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav" v-if="!isMobile">
        <p class="user-name mb-0">
          <img :src="getBadgeSrc(badge)" height="25px" style="margin-right: 5px;" :class="{ 'glowing-image': grade > 20 }"/> 
          {{ userloginname }}
          <feather-icon icon="SettingsIcon" size="21" style="margin-left: 10px;"/>
        </p>
      </div>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="changePassword">
      <feather-icon size="16" icon="LockIcon" class="mr-50"/>
      <span>Ubah Password</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { isMobile } from "mobile-device-detect"

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      // userData: JSON.parse(localStorage.getItem('userData')),
      userloginname: localStorage.getItem('user_login_name'),
      grade: localStorage.getItem('grade'),
      badge: localStorage.getItem('badge'),
      name: localStorage.getItem('name'),
      nik: localStorage.getItem('nik'),
      token: localStorage.getItem('token'),
      department: localStorage.getItem('department'),
      division: localStorage.getItem('division'),
      level: localStorage.getItem('level'),
      avatarText,
      isMobile: isMobile,
    }
  },
  methods: {
    init(){
      this.getBadgeSrc(this.badge);
    },
    getBadgeSrc(path) {
      if(path == 'serpa.jpg'){
        return require('@/assets/images/avatars/serpa.jpg');
      }else if(path == 'serdu.jpg'){
        return require('@/assets/images/avatars/serdu.jpg');
      }else if(path == 'serpol.jpg'){
        return require('@/assets/images/avatars/serpol.jpg');
      }else if(path == 'serka.jpg'){
        return require('@/assets/images/avatars/serka.jpg');
      }else if(path == 'pelma.jpg'){
        return require('@/assets/images/avatars/pelma.jpg');
      }else if(path == 'peldu.jpg'){
        return require('@/assets/images/avatars/peldu.jpg');
      }else if(path == 'letma.jpg'){
        return require('@/assets/images/avatars/letma.jpg');
      }else if(path == 'letdu.jpg'){
        return require('@/assets/images/avatars/letdu.jpg');
      }else if(path == 'kapten.jpg'){
        return require('@/assets/images/avatars/kapten.jpg');
      }else if(path == 'mayor.jpg'){
        return require('@/assets/images/avatars/mayor.jpg');
      }else if(path == 'letkol.jpg'){
        return require('@/assets/images/avatars/letkol.jpg');
      }else if(path == 'kolonel.jpg'){
        return require('@/assets/images/avatars/kolonel.jpg');
      }else if(path == 'serjen.jpg'){
        return require('@/assets/images/avatars/serjen.jpg');
      }else if(path == 'mayjen.jpg'){
        return require('@/assets/images/avatars/mayjen.jpg');
      }else if(path == 'letjen.jpg'){
        return require('@/assets/images/avatars/letjen.jpg');
      }else if(path == 'jendral.jpg'){
        return require('@/assets/images/avatars/jendral.jpg');
      }else if(path == 'kadop.jpg'){
        return require('@/assets/images/avatars/kadop.jpg');
      }else if(path == 'sekertaris.jpg'){
        return require('@/assets/images/avatars/sekertaris.jpg');
      }
    },
    changePassword() {
      this.$router.push({ path: '/idp-chpass' });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.clear()
      // Redirect to login page
      this.$router.push({ path: '/login' })

      // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'login' })
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style>
@keyframes glowZoom {
    0% {
        transform: scale(1);
        filter: brightness(1);
    }
    50% {
        transform: scale(1.1); /* Zoom sedikit */
        filter: brightness(1.4); /* Efek pendar */
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
    }
}

/* Kelas untuk gambar dengan efek glowing dan zoom */
.glowing-image {
    animation: glowZoom 1.3s infinite; /* Durasi dan infinite loop */
}
</style>