<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <!-- <template #button-content>
      <template v-if="notifications.length === 0">
        <feather-icon class="text-body" icon="BellIcon" size="21" />
      </template>

      <template v-else>
        <feather-icon
          :badge="notifications.length"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>
    </template> -->
    <!-- Header -->
    <!-- <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
      </div>
    </li> -->

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <template>
        <div v-if="notifications.length > 0">
          <b-link
            v-for="(item, notification) in notifications"
            :key="notification"
            :router="true"
            :index="item.route"
          >
            <b-media @click="getMessages(item)">
              <template #aside>
                <b-avatar
                  size="32"
                  :text="item.avatar"
                  :variant="item.type"
                />
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ item.title }}
                </span>
              </p>
              <small
                class="notification-text"
                v-html="item.subtitle"
              />
            </b-media>
          </b-link>
        </div>
        <div v-else>
          <b-link>
            <b-media>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  You dont have any notification
                </span>
              </p>
            </b-media>
          </b-link>
        </div>
      </template>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!-- <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Peraturan Perusahaan</b-button>
    </li> -->

    <b-modal
      ref="myModalRef"
      ok-only
      ok-title="Close"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      size="lg"
      centered
      modal-header-close
    >
      <div
        slot="modal-header"
        class="text-center"
        style="margin: 0 auto"
      >
        <h3 class="text-center">
          {{ title }}
        </h3>
      </div>
      <div
        id="textbox"
        style="padding: 20px"
      >
        <p class="alignleft">
          <i class="fa fa-user" /> : <strong>{{ from }}</strong>
        </p>
        <p class="alignright">
          <i class="fa fa-calendar" /> : <strong>{{ date }}</strong>
        </p>
        <br>
        <br>
        <div
          style="background-color: #e8e8e8; padding: 20px"
          v-html="messages"
        />
        <br>
        <!-- <b-button v-if="file !== null" type="button" size="sm" variant="primary" @click="downloadfile(file, id)"><i class="fa fa-save"></i> Download File</b-button> -->
        <b-button
          v-if="file !== null"
          type="button"
          size="sm"
          variant="link"
          @click="previewFile(file, id)"
        ><i class="fa fa-paperclip" /> Download File</b-button>
      </div>
    </b-modal>

    <!-- modal preview -->
    <b-modal
      id="previewModalAttachment"
      ref="previewModal"
      header-border-variant="no-border text-center"
      footer-border-variant="no-border"
      footer-bg-variant="light"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="lg"
    >
      <div
        slot="modal-header"
        class="text-center"
      >
        <h3 class="text-center">
          Preview
        </h3>
      </div>
      <b-form>
        <b-form-group>
          <img
            v-if="show_img_preview"
            class="img"
            :src="img_preview"
          >
          <iframe
            v-if="show_pdf_preview"
            class="frame"
            :src="pdf_preview"
          />
        </b-form-group>
      </b-form>
      <loading v-if="loading" />

      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          size="sm"
          variant="danger"
          @click="cancelPreview"
        ><i class="fa fa-undo" /> Back</b-button>
      </div>
    </b-modal>
    <!-- end modal preview -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = ref([])

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    // const notif = ref();

    return {
      notifications,
      perfectScrollbarSettings,
      nik: localStorage.getItem('nik'),
      myNIK: String(localStorage.getItem('nik')),
      loading: false,
      notif: [],
      messages: [],
      from: [],
      title: null,
      date: [],
      file: [],
      show_img_preview: false,
      show_pdf_preview: false,
      img_preview: '',
      pdf_preview: '',
      // data: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.Loading = false
      // this.getData();
    },
    previewFile(e, g) {
      const split = e.split('.')
      let file_extention = ''
      split.map((value, key) => {
        file_extention = value.toLowerCase()
      })
      if (
        file_extention === 'png'
        || file_extention === 'jpg'
        || file_extention === 'jpeg'
      ) {
        this.show_img_preview = true
        this.show_pdf_preview = false
        // this.attachment_name = a.attachment_type_name;
        this.img_preview = `${axios.defaults.upload_path}${e}`
        this.$refs.previewModal.show()
      } else if (file_extention === 'pdf') {
        this.show_pdf_preview = true
        this.show_img_preview = false
        // this.attachment_name = a.attachment_type_name;
        this.pdf_preview = `${axios.defaults.upload_path}${e}`
        window.open(this.pdf_preview)
        // this.$refs.previewModal.show();
      } else {
        alert("Can't Preview Please Download")
      }
      // this.isEdit = true;
      // this.customer_attachment = a;
      // this.$refs.previewModal.show();
    },
    downloadfile(e, g) {
      // window.location.href = axios.defaults.baseURL+'notification/download-notification/'+this.id+'/';
      const str = e
      const file_name = str.split('/').pop()

      this.loading = true
      const url = `${axios.defaults.download_assets_url}/`
      const id = g
      // api request
      axios({
        url: url + id,
        method: 'GET',
        responseType: 'stream', // before "blob"
      })
        .then(response => {
          // file download
          const fileDownload = require('js-file-download')
          fileDownload(response.e, file_name)
        })
        .catch(error => {
          this.expired(error, 'Error')
        })
        .finally(() => {
          // remove loading & show button lagi
          this.loading = false
        })
    },
    cancelPreview() {
      this.$refs.previewModal.hide()
      this.show_pdf_preview = false
    },
  },
}
</script>

<style>
.alignleft {
  float: left;
  line-height: 0em;
}
.alignright {
  float: right;
}
.modal-content {
  margin-top: 50px;
}
.modal-title {
  font-size: 24px;
  margin: 0 auto;
}

img.img {
  display: block;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

iframe.frame {
  display: block;
  width: 100%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}
</style>
